import axios from "@/axios.config.js";

const ratingsIndex = (params) => axios.get('/api/admin/v1/ratings',{
  params:{
    page: params?.page ?? 1,
    rating: params?.rating == 0 ? '' : params.rating
  }
})

const exportRating = (body) => axios.post('/api/admin/v1/ratings/export',body,{
  responseType: 'blob'
})
 
export {
  ratingsIndex,
  exportRating
}