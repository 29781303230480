<template>
  <div class="feedback-container">
    <h3>{{feedbackData.user.name || 'Player'}}</h3>
    <StarRating 
      :show-rating="false" 
      v-model="feedbackData.rating" 
      :read-only="true"
      :star-size="30"
      ></StarRating>
    <p class="feedback-comment">{{feedbackData.review}}</p>
    <p class="feedback-date">{{formatDate || "Date"}}</p>
    <hr>
  </div>
</template>

<script>
import moment from 'moment';


import StarRating from 'vue-star-rating'
export default {
  name: 'FeedbackItem',
  components:{
    StarRating
  },
  props:['feedbackData'],
  computed:{
    formatDate(){
      return moment(this.feedbackData.updated_at).format("DD MMM YYYY h:mm a")
    }
  }

}
</script>

<style lang="scss" scoped>
.feedback-container{
  margin-top: 2rem;
  
  @media screen and (max-width:450px){
    margin: 1rem 1rem;
  }

  h3{
    font-weight: normal;
    color: $grayDark;
    margin-bottom: .5rem;
  }

  .feedback-comment{
    margin: .5rem 0;
    color: $grayDark;
  }

  .feedback-date{
    margin-bottom: 1rem;
    font-size: 14px;
    color: lighten($color: $grayDark, $amount: 30%);
  }

  hr{
    color: #E9D6D6
  }
}
</style>