<template>
   <ContentWrapper :fullHeight="true">
    <template v-slot:title>Feedback</template>
    <Container>
      <section class="heading">
         <div class="filterSection">
          <SubmitButton @onButtonClick="updateFilter(0)" :disabled="currentFilter != 0">All</SubmitButton>
          <SubmitButton @onButtonClick="updateFilter(5)" :disabled="currentFilter != 5">5 Star</SubmitButton>
          <SubmitButton @onButtonClick="updateFilter(4)" :disabled="currentFilter != 4">4 Star</SubmitButton>
          <SubmitButton @onButtonClick="updateFilter(3)" :disabled="currentFilter != 3">3 Star</SubmitButton>
          <SubmitButton @onButtonClick="updateFilter(2)" :disabled="currentFilter != 2">2 Star</SubmitButton>
          <SubmitButton @onButtonClick="updateFilter(1)" :disabled="currentFilter != 1">1 Star</SubmitButton>
        </div>
        <div class="export">
          <h3>Export Feedback</h3>
          <div class="field">
            <datepicker v-model="date.start_date" placeholder="Start Date" format="dd-MM-yyyy" class="date-picker"></datepicker>
            <datepicker v-model="date.end_date" placeholder="End Date" format="dd-MM-yyyy" class="date-picker"></datepicker>
          </div>
           <SubmitButton @onButtonClick="exportData">Export</SubmitButton>
        </div>
      </section>
      <FeedbackItem v-for="feedback in feedbackData" :key="feedback.id" :feedbackData="feedback"/>
      <div class="loadMoreBtn" v-if="totalData > totalDisplayingData" @click="getRatingsIndex({page: currentPage + 1})">Load More</div>
    </Container>
  </ContentWrapper>
</template>

<script>
import SubmitButton from '@/components/SubmitButton';
import FeedbackItem from  './components/FeedbackItem';
import Datepicker from 'vuejs-datepicker';
import { saveAs } from 'file-saver';
import {eventBus} from '@/main.js';

//API
import {ratingsIndex,exportRating} from '@/services/adminAPI/ratings.js';

import moment from "moment";

export default {
  components:{
    SubmitButton,
    FeedbackItem,
    Datepicker
  },
  data(){
    return{
      currentFilter : 0,
      feedbackData:[],
      totalData: 0,
      totalDisplayingData: 0,
      perPage: 0,
      currentPage: 0,
      date:{
        start_date: null,
        end_date: null,
      }
    }
  },
  methods:{
    updateFilter(data){
      this.currentFilter = data;
    },
    async getRatingsIndex({page = 1}){
      eventBus.$emit('loader',true);
      const ratingsIndexAPI = await ratingsIndex({page,rating: this.currentFilter});
      eventBus.$emit('loader',false);
      
      if(page === 1){
        this.totalData = ratingsIndexAPI.data.response.ratings.total;
        this.perPage = ratingsIndexAPI.data.response.ratings.per_page;
        this.feedbackData = ratingsIndexAPI.data.response.ratings.data;
      }else{
        this.feedbackData = [...this.feedbackData, ...ratingsIndexAPI.data.response.ratings.data];
      }
      this.totalDisplayingData = ratingsIndexAPI.data.response.ratings.to;
      this.currentPage = ratingsIndexAPI.data.response.ratings.current_page;

    },
    formatDate(date){
       return moment(date).format('DD-MM-yyyy');
    },
    async exportData(){

      const body = {
        start_date : this.formatDate(this.date.start_date),
        end_date : this.formatDate(this.date.end_date),
      }

      try {
        eventBus.$emit('loader',true);

       const request = await exportRating(body);

        console.log(request);


        const a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('type', 'hidden');
        
        let blob = new Blob([request.data], {type: "octet/stream"})
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `feedback_${body.start_date}_${body.end_date}.xlsx`;
        a.click()
        eventBus.$emit('loader',false);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        eventBus.$emit('loader',false);
        this.$swal({
          icon: 'error',
          title: 'Oops!',
          text: error.response.data.userMessage
        })
      }
    }
  },
  async created(){
    await this.getRatingsIndex({});
  },
  watch:{
    currentFilter(data,oldData){
      if(data != oldData){
        this.getRatingsIndex({})
      }
    } 
  }
}
</script>

<style lang="scss" scoped> 

  .content-container{
    @media screen and (max-width:450px){
      padding: 0;
      height: calc(100vh - 60px - 58px);
    }
  }

  .export{
    padding: 10px 0;
    
    @media screen and (max-width: 450px){
      padding: 20px;
    }

    .field{
      display: grid;
      grid-template-columns: repeat(2,170px);
      grid-gap: 20px;
      margin: 15px 0;

      @media screen and (max-width:450px){
        display: block;
      }

      
      .date-picker:first-child{
        margin-bottom: 20px;
      }

      /deep/ input{
        letter-spacing: 1px;
        width: 100%;
        border: none;
        padding: 1rem 0;
        padding-left: 1rem;
        color: var(--gray414241);
        border: 1px solid var(--borderGrayECECEC);
        border-radius: 5px;

        @media screen and (max-width:450px){
          
        }

        
      }
    }

    button{
      @media screen and (max-width:450px){
        display: block;
        width: 100%;
      }
    }
    
  }

  .filterSection{
    display: flex;
    flex-wrap: wrap;
  
    & > *{
      flex: 0 0 100px;
      padding: .5em 0;
      margin-right: 1rem;
      margin-bottom: 1rem;


      @media screen and (max-width:600px){
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }

      @media screen and (max-width:450px){
        border-radius: 0px;
        margin: 0;
        font-size: 14px;
        flex-grow: 1;
      }
    }
  }

  .loadMoreBtn{
    margin: 3rem auto;
    text-align: center;
    padding: 1rem;
    background: lighten($color: $grayDark, $amount: 70%);
    cursor: pointer;
  }
</style>